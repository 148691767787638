<template>
   <div class="col-12">
      <div class="card mb-1" :class="index % 2 == 1 ? 'invert' : ''">
         <div class="card-body p-12">
            <div class="row align-items-center">
               <div class="col">
                  <h1 class="font-15 mb-0 limitador-1 text-uppercase">{{ subcategoria.nome }}</h1>
                  <p class="font-10 mb-0 limitador-1 text-secondary"><i class="far fa-hashtag font-9 me-1"></i>{{ subcategoria.id == null ? '?' : subcategoria.id }} </p>
               </div>
               <div class="w-max-content font-16 text-center ms-3 ms-sm-0" v-if="dadosUsuario.usuarioPermissoes.includes('Produtos | Editar categorias')">
                  <div class="btn-icone text-red">
                     <span @click="remover"><i class="far fa-trash "></i><small>Excluir</small></span>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</template>

<script>

import { mapState } from 'vuex'

export default {
   name: 'Subcategoria',
   props: ['subcategoria', 'index'],
   computed: {
      ... mapState({
         dadosUsuario: state => state.dadosUsuario
      })
   },
   methods: {
      imageError : function (e) {
         this.$store.dispatch('imageError', e)
      },
      remover : function () {
         this.$emit('remover', this.index)
      }
   }
}

</script>

<style scoped>

img {
   padding: 2px;
}

</style>