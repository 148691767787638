<template>
   <div class="col-xl-6 mb-1" ref="categoria">
      <div class="card mb-0" :class="index % 4 == 0 || index % 4 == 3 ? 'invert' : ''">
         <div class="card-body p-12">
            <div class="row align-items-center">
               <div class="w-max-content pe-12 d-none d-sm-block">
                  <img :src="categoria.banner == null ? '' : categoria.banner" class="rounded" alt="icon" @error="imageError" height="50" />
               </div>
               <div class="col-sm text-center text-sm-start cursor-pointer" @click="editar">
                  <h1 class="font-15 mb-0 limitador-1 text-uppercase">{{ categoria.nome }}</h1>
                  <p class="font-10 mb-1 limitador-1 text-secondary"><i class="far fa-hashtag font-9 me-1"></i>{{ categoria.id }} </p>
                  <p class="font-12 mb-0 limitador-1">
                     <i class="far fa-copy color-theme font-10 me-1"></i><strong class="subtitle me-1 d-none d-lg-inline"> Subcategorias:</strong>
                     <span class="text-capitalize"> {{ categoria.subcategorias.length == 0 ? 'Sem subcategorias' : String(categoria.subcategorias.map(sub => sub.nome)).replace(/,/g, ", ").toLowerCase() }}</span>
                  </p>
               </div>
               <div class="w-max-content d-flex align-items-center font-16 text-center mx-auto mx-md-0 mt-2 mt-md-0" v-if="dadosUsuario.usuarioPermissoes.includes('Produtos | Editar categorias')">
                  <div class="btn-icone color-theme">
                     <span @click="editar"><i class="far fa-pen "></i><small>Editar</small></span>
                  </div>
                  <div class="btn-icone text-red">
                     <span @click="remover"><i class="far fa-trash "></i><small>Excluir</small></span>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</template>

<script>

import Swal from 'sweetalert2'
import { mapState } from 'vuex'

export default {
   name: 'Categoria',
   props: ['categoria', 'index'],
   computed: {
      ... mapState({
         dadosUsuario: state => state.dadosUsuario,
         urlRest: state => state.urlRest
      })
   },
   methods: {
      imageError : function (e) {
         this.$store.dispatch('imageError', e)
      },
      editar : function () {
         if (this.dadosUsuario.usuarioPermissoes.includes('Produtos | Editar categorias')) {
            this.$emit('editar', this.categoria)
         }
      },
      remover : function () {
         Swal.fire({
            icon: 'warning',
            title: 'Excluir categoria?',
            text: 'A categoria e suas subcategorias serão excluídas.',
            showCancelButton: true,
            confirmButtonText: 'Confirmar',
            cancelButtonText: `Cancelar`

         }).then((result) => {
            if (result.isConfirmed) {
               this.$store.dispatch('alternarTelaCarregamento', true)

               this.$axios({
                  method: 'post',
                  url: this.urlRest +'produtos/deleteCategoria',
                  params: {
                     id: this.categoria.id
                  }
               }).then(() => {
                  this.$refs.categoria.style.display = 'none'

                  this.$toast.fire({
                     icon: 'success',
                     title: 'Categoria excluída!'
                  })
               }).catch((error) => {
                  if (error.response != undefined) {
                     if (error.response.status == 408) {
                        this.$store.dispatch('deslogar')

                        this.$toast.fire({
                           icon: 'warning',
                           title: 'Sessão expirada!'
                        });
                     } else if (error.response.status == 409) {
                        this.$toast.fire({
                           icon: 'error',
                           title: 'Existem produto(s) vinculados!'
                        });
                     } else {
                        this.$toast.fire({
                           icon: 'error',
                           title: 'Erro: ' + error.response.status
                        })
                     }
                  }
               }).finally(() => {
                  this.$store.dispatch('alternarTelaCarregamento', false)
               });
            }
         });
      }
   }
}

</script>

<style scoped>

img {
   padding: 2px;
}

</style>